import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import store from "./store/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
const client = new QueryClient();
const google_analytics_key = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize(google_analytics_key);
root.render(
  <Provider store={store}>
    <QueryClientProvider client={client}>

        <BrowserRouter>
          <App />
          <Toaster />
        </BrowserRouter>
    </QueryClientProvider>
  </Provider >
);
const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);