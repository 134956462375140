import React, { useCallback, useEffect, useState } from "react";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import ExploreDropDown from "../Navbar3/ExploreDropdown/ExploreDropDown";
import "../Navbar.css";
import { IoIosArrowDown } from "react-icons/io";
import Ham from "../../Drawers/Drawer/Ham";
import ExploreDrawer from "../../Drawers/Drawer/ExploreDrawer";
import {
  useDisclosure,
  ChakraProvider,
  PopoverContent,
  PopoverTrigger,
  Popover,
  Avatar,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { NAV_ITEMS } from "../../../Assets/constants/constants";
import NavItem from "../Navbar3/NavItem";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../store/reducers/user";
import LoginModal from "../../Modals/LoginModal";
import Authdrawer from "../../Drawers/Auth Drawers/Authdrawer";
import AccountDrawer from "../../Drawers/Drawer/AccountDrawer";

export default function Navbar({ userInfo }) {
  const [isOpenHam, setIsOpenHam] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isCookie, setIsCookie] = useState(
    document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1")
  );
  const [filepath, setFilepath] = useState(
    localStorage.getItem("profileURL") ? localStorage.getItem("profileURL") : ""
  );
  const profile_var = localStorage.getItem("profile");

  // redux used for model opening and
  const dispatch = useDispatch();
  const { authType, redirectUrl, authenticated, openLoginDrawerState } =
    useSelector((state) => state.user);
  // console.log("Auth Type:", authType);

  const handleLoginClick = () => {
    dispatch(userActions.setRedirectUrl(`${process.env.REACT_APP_BASE_URL}`));
    dispatch(userActions.setLoginOpenFromProgramPage(null));
    dispatch(userActions.openLoginModal("login"));
  };

  useEffect(() => {
    if (!isCookie) {
      setIsCookie("");
      localStorage.removeItem("token");
    }
  }, [isCookie]);

  // useEffect(()=>{
  //   if (authenticated) {
  //     window.location.reload();
  //   }
  // }, [authenticated])

  useEffect(() => {
    const url = new URL(window.location.href);
    const authStatus = url.searchParams.get("auth_ref");
    if (authStatus) {
      loginClick();
      setTimeout(() => {
        url.searchParams.delete("auth_ref");
        window.history.replaceState(null, "", url);
      }, 1000);
    }
  }, []);

  // const openModel = () => {
  //   setIsOpenModel(true);
  // };
  // const closeLoginModel = () => {
  //   setIsOpenModel(false); // Function to close the login model
  // };

  // const fetchData = useCallback(async () => {
  //   await axios({
  //     method: "post",
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Content-Type": "application/json",
  //     },
  //     url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/profileDetails`,
  //     data: {
  //       user_id: localStorage.getItem("user_id"),
  //     },
  //   })
  //     .then((res) => {
  //       // console.log(res);
  //       if (res.data.status == "300") {
  //         setFilepath("");
  //       } else {
  //         localStorage.setItem("profileURL", res.data[0].filename);
  //         setFilepath(res.data[0]?.filename);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  // useEffect(() => {
  //   if (!localStorage.getItem("profileURL")) {
  //     fetchData();
  //   }
  // }, [profile_var, fetchData]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: authIsOpen,
    onOpen: onAuthOpen,
    onClose: authOnClose,
  } = useDisclosure();
  // const {}
  // const {
  //   isOpen: myAccountIsOpen,
  //   onOpen: myAccountOnOpen,
  //   onClose: myAccountOnClose,
  // } = useDisclosure();

  // const onMyAccountClick = () => {
  //   console.log("I got clicked");
  //   setIsOpenHam(false);
  //   myAccountOnOpen();
  // };

  const loginClick = () => {
    if (width <= 768) {
      openLoginDrawer();
    } else {
      handleLoginClick();
    }
  };
  const handleClick = () => {
    onOpen();
  };
  const openLoginDrawer = () => {
    dispatch(userActions.setRedirectUrl(`${process.env.REACT_APP_BASE_URL}`));
    dispatch(userActions.setLoginOpenFromProgramPage(null));
    dispatch(userActions.setDrawerState(true));
  };
  const closeLoginDrawer = () => {
    // dispatch(userActions.setRedirectUrl(`${process.env.REACT_APP_BASE_URL}`));
    // dispatch(userActions.setLoginOpenFromProgramPage(null))
    dispatch(userActions.setDrawerState(false));
  };
  const navigate = useNavigate();

  const LogOut = (name, domain) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
    console.log(document.cookie);
    localStorage.removeItem("user_id");
    localStorage.clear();
    dispatch(userActions.setAuthenticatedState(false));
    // setIsCookie(false);
    // setUserInfo("");
    // navigate(`/Login`);
    // navigate('/')
    window.location.href = process.env.REACT_APP_BASE_URL;
  };

  return (
    <>
      <div className="nav-3 flex justify-center py-[0.6rem] w-full px-4 xl:px-12">
        <div className="nav-3-inner">
          <div className="nav-3-left">
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <div className="max-w-[125px] w-full">
                <img
                  className="w-full"
                  alt="logo"
                  src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/logo.webp"
                />
              </div>
            </Link>
            <ExploreDropDown />
          </div>
          <div className="nav-3-right">
            <div className="nav-3-right-inner">
              <ChakraProvider>
                {NAV_ITEMS.map((item, index) => (
                  <NavItem key={`nav_item_parent_key_${index}`} item={item} />
                ))}
              </ChakraProvider>
            </div>
            {isCookie ? (
              <div className="xl:flex hidden">
                <ChakraProvider>
                  <Popover trigger="hover" openDelay={100}>
                    <PopoverTrigger>
                      <div className="flex items-center gap-2 max-w-[160px] cursor-pointer hover:bg-blue-200/30 py-2 px-3 rounded-md">
                        <Avatar
                          bg="#1A73E8"
                          size={"sm"}
                          src={filepath ? filepath : ""}
                        />
                        <h2 className="font-medium truncate">
                          {localStorage.getItem("name")}
                        </h2>
                      </div>
                    </PopoverTrigger>
                    <PopoverContent top={3} className="pop-content-user">
                      <a
                        href={`${process.env.REACT_APP_DASHBOARD_BASE_URL}/?tab=my-applications`}
                        className="py-2 px-4 hover:text-universal hover:bg-universal/15 rounded-md cursor-pointer w-full"
                      >
                        <h2 className="font-medium capitalize">
                          My Application
                        </h2>
                      </a>

                      <a
                        href={`${process.env.REACT_APP_DASHBOARD_BASE_URL}/?tab=my-courses`}
                        className="py-2 px-4 hover:text-universal hover:bg-universal/15 rounded-md cursor-pointer w-full"
                      >
                        <h2 className="font-medium capitalize">My Courses</h2>
                      </a>
                      <button
                        className="py-2 px-4 hover:text-red-500 text-start hover:bg-red-400/30 rounded-md cursor-pointer"
                        onClick={() => {
                          // LogOut("token", "accredian.com");
                          LogOut("token", "accredian.com");
                        }}
                      >
                        <h2 className="font-medium capitalize">Logout</h2>
                      </button>

                      <a
                        href={`${process.env.REACT_APP_BASE_URL}/referandearn`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/dropDownBanner-v2.webp"
                          className="w-[240px]"
                          alt=""
                        />
                      </a>
                    </PopoverContent>
                  </Popover>
                </ChakraProvider>
              </div>
            ) : (
              <div className="hidden xl:flex items-center justify-center gap-[1rem]">
                {/* <Link to="/login">
                  <button className=" px-[18px] py-[8px] bg-slate-400/20 rounded-md font-medium text-base text-nowrap">
                    Login
                  </button>
                </Link> */}

                {/* TO open the login model I have used dispatch */}
                <button
                  onClick={handleLoginClick}
                  className=" px-[18px] py-[8px] bg-slate-400/20 rounded-md font-medium text-base text-nowrap"
                >
                  Login
                </button>
                {/* </Link> */}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://trial.accredian.com"
                  className="lg:block hidden"
                >
                  <button className="px-[18px] py-[8px] bg-universal hover:bg-darkBlue text-white rounded-md font-medium text-base text-nowrap">
                    Try for free
                  </button>
                </a>
              </div>
            )}
            <button
              className="explore-2  bg-universal text-white md:hidden flex items-center gap-1 font-medium px-3 py-1 rounded-sm"
              onClick={() => handleClick()}
            >
              Explore
              <IoIosArrowDown className="-rotate-90" />
            </button>
            <HiOutlineMenuAlt3
              size={28}
              className="text-neutral-800 xl:hidden block cursor-pointer"
              onClick={() => setIsOpenHam(!isOpenHam)}
            />
          </div>
        </div>
      </div>
      <Ham
        onLoginClick={loginClick}
        // onLoginClick={setIsLoginOpen}
        isLoginOpen={isLoginOpen}
        LogOut={LogOut}
        // userInfo={userInfo}
        loginStatus={isCookie}
        isOpen={isOpenHam}
        setIsOpen={setIsOpenHam}
        // openMyAccountDrawer={onMyAccountClick}
      />
      {/* <AccountDrawer isOpen={myAccountIsOpen} onClose={myAccountOnClose} /> */}
      <ExploreDrawer isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      <Authdrawer isOpen={openLoginDrawerState} onClose={closeLoginDrawer} />

      {/* TO close the login model redux is used */}
      <div>
        {authType && (
          <LoginModal open={authType !== null && authType !== "loginsuccess"} />
        )}
      </div>
      {/* <div className="hidden" > */}
      {/* <LoginDrawer />
      </div> */}
    </>
  );
}
