import { useNavigate } from "react-router-dom";
import { STREAMS } from "../../../Assets/constants/constants";
import "./verticals.css";
import programs from "../../../Assets/constants/programs.json";
import { useEffect, useState } from "react";
import { groupProgramsByCategory } from "../../../helpers/common.helpers";

const Verticals = () => {
  const sortedStreams = STREAMS.slice().sort((a, b) => a.order - b.order);
  const [categorizedPrograms, setCategorizedPrograms] = useState([]);

  useEffect(() => {
    function setData() {
      const fetchPrograms = groupProgramsByCategory(programs);
      setCategorizedPrograms(fetchPrograms || []);
    }
    setData();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full flex justify-center mt-16 font-circular xl:px-12 px-4 py-8">
        <div className="w-full max-w-[86rem] h-full flex flex-col gap-4">
          <div className="w-full flex md:flex-row flex-col justify-normal md:justify-between px-[10px] lg:gap-8 gap-4 items-start md:items-end">
            <div className="w-full">
              <h4 className="text-base text-darkBlue font-medium max-sm:max-w-[300px]">
                VERTICALS
              </h4>
              <h1 className="text-3xl lg:text-[44px] mt-2 font-medium capitalize">
                Browse interested
                <span className="text-universal"> verticals</span>
              </h1>
            </div>
          </div>

          <div className="flex justify-center mt-4">
            <div className="w-full max-w-[76rem] flex justify-center items-center gap-4 mt-4 flex-wrap">
              {/* <div className="w-full max-w-[90vw] lg:max-w-[60rem] xl:max-w-[78rem]"> */}
              {sortedStreams.map((val, index) => {
                let vertical = val.category.toLowerCase();
                return (
                  <div
                    className="max-w-[330px] hover:bg-gray-100 hover:transition ease-in-out hover:shadow-xl w-full bg-white px-8 py-4 rounded-xl drop-shadow font-dmSans flex flex-col justify-between cursor-pointer group"
                    onClick={() =>
                      window.open(
                        `${window.location.origin
                        }/course/${val.category.replace(/ /g, "-")}`,
                        "_blank"
                      )
                    }
                  >
                    <div className="flex items-center gap-4">
                      <div
                        className="w-10 h-10 relative bg-universal/15 bg-white 
                    group-hover:transition ease-in-out group-hover:bg-gray-100"
                      >
                        <val.icon className="w-full h-full text-universal"></val.icon>
                      </div>
                      <div className="">
                        <h1 class="text-lg font-medium capitalize">
                          {val.category}
                        </h1>
                        <h4 className="text-gray-500">
                          {/* {categorizedPrograms[vertical]?.length
                          ? categorizedPrograms[vertical].length
                          : 0}{" "} */}
                          {categorizedPrograms[vertical]?.length}{" "}
                          {categorizedPrograms[vertical]?.length === 1
                            ? "Course"
                            : "Courses"}
                        </h4>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verticals;
