
import { useEffect, useState } from "react";
function Sitemap() {
    const [sitemap, setSitemap] = useState("");
    useEffect(() => {
        fetch("/sitemap.xml")
            .then((res) => res.text())
            .then((data) => setSitemap(data));
    }, []);
    return <pre>{sitemap}</pre>;
}
export default Sitemap;