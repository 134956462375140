import { RotateCcw } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";

const UpdateNotification = () => {
  // Update the current verion to the version whihc is beeing pushed 

  const [currentVersion, setCurrentVersion] = useState('')

  fetch("/version.json")
    .then(response => response.json())
    .then(data => {
      setCurrentVersion(data.version)
    })
    .catch(error => console.error("Error loading version:", error));

  // const CURRENT_VERSION = "0.1.10";
  // update this url with the website url
  const VERSION_URL = `${process.env.REACT_APP_BASE_URL}/version.json`; // URL of the hosted version file

  const [showNotification, setShowNotification] = useState(false);

  const checkForUpdates = useCallback(async () => {
    try {
      const response = await fetch(VERSION_URL);
      const data = await response.json();
      if (data.version !== currentVersion) {
        setShowNotification(true);
      } else {
        setShowNotification(false);
      }
    } catch (error) {
      console.error("Error checking for updates:", error);
    }
  }, [VERSION_URL, currentVersion])
   
  useEffect(() => {
    checkForUpdates();
    const updateInterval = setInterval(checkForUpdates, 1000 * 60); // Check for updates every minute
    return () => clearInterval(updateInterval);
  }, [checkForUpdates]);

  return (
    <>
      {showNotification && (
        // <div className="flex h-full items-start md:items-end ">
        <div
          id="notification"
          className="fixed right-[1%] bottom-[3%] shadow-lg bg-white p-5 rounded-md text-sm z-[99999999] w-[450px] font-circular cursor-context-menu border border-universal/25"
        >
          {/* <button
            onClick={() => setShowNotification(false)}
            className="absolute top-2 right-2 bg-transparent border-none text-lg cursor-pointer font-bold"
          >
            <X />
          </button> */}
          <div className="flex items-center gap-4">
            <div className="max-w-1/2 w-full">
              <h2 className="font-semibold text-lg mb-2">New Update Available!</h2>
              <p className="">
                Please click refersh button to see the changes.
              </p>
            </div>
            <div>
              <button
                onClick={() => window.location.reload()}
                className="text-center rounded-md text-white p-2 border border-universal/15 hover:bg-universal/25"
              >
                <RotateCcw className="text-universal" />
              </button>
            </div>
          </div>
        </div>
        // </div>
      )}
    </>
  );
};

export default UpdateNotification;
