import { Drawer, DrawerBody, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IoChevronBackOutline, IoClose } from 'react-icons/io5'
import { groupProgramsByCategory, sortVerticalProgramsByStatus } from '../../../helpers/common.helpers'
import programs from "../../../Assets/constants/programs.json"
import { getCardColorAndBgColor } from '../../../helpers/colors.helpers'

const ExploreDrawerChild = ({ innerIsOpen, innerOnClose, streamWithTab, handleCloseAll }) => {

  const [categorizedPrograms, setCategorizedPrograms] = useState([])

  useEffect(() => {
    const fetchPrograms = groupProgramsByCategory(programs)
    const sortedPrograms = sortVerticalProgramsByStatus(fetchPrograms)
    setCategorizedPrograms(sortedPrograms || [])
  }, [])

  return (
    <Drawer onClose={innerOnClose} isOpen={innerIsOpen} size={"full"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody>
          <div className='flex-1 py-[26px] font-circular'>
            <div className='w-full py-2 flex items-center justify-between'>
              <button className='flex items-center gap-1 font-medium py-1' onClick={() => innerOnClose()}>
                <IoChevronBackOutline />
                Back
              </button>
              <button className='flex items-center gap-1 font-medium py-1 px-1 text-lg hover:bg-neutral-300/30' onClick={handleCloseAll}>
                <IoClose />
              </button>
            </div>
            {streamWithTab && (
              <>
                <h1 className='font-bold text-2xl capitalize mt-2 mx-1'>{streamWithTab}</h1>
                <div className='w-full flex flex-wrap mt-4 py-2 max-h-[70vh] px-1 overflow-y-scroll gap-4 custom-scrollbar'>
                  {categorizedPrograms && categorizedPrograms[streamWithTab.toLowerCase()]?.map((val, index) => (
                    <a onClick={handleCloseAll} href={`/programs/${val.id}`} className='w-full h-40 bg-white drop-shadow-md rounded-lg' key={index} type='div'>
                      <div className='w-full h-full flex flex-col justify-between'>
                        <div className='w-full'>
                          <div className='w-full flex justify-end px-4'>
                            <div
                              style={{
                                backgroundColor: `${getCardColorAndBgColor(val.status).bgColor}`
                              }}
                              className={`px-3 py-[2px] rounded-b-lg`}
                            >
                              <h1
                                style={{
                                  color: `${getCardColorAndBgColor(val.status).color}`
                                }}
                                className={`font-bold text-xs capitalize`}
                              >
                                {val.status}</h1>
                            </div>
                          </div>
                          <div className='w-full px-4 flex gap-2 items-start mt-2'>
                            <div className='w-8 h-8 mt-1 flex-shrink-0 border rounded-md'>
                              <img
                                src={val.university_image}
                                alt="logo"
                                className='w-full h-full'
                              />
                            </div>
                            <div className='flex flex-col'>
                              <h1 className='text-xs text-neutral-600 capitalize'>{val.university_name}</h1>
                              <h1 className='text-neutral-700 font-medium'>{val.program_name}</h1>
                            </div>
                          </div>
                        </div>
                        <div className='w-full px-4 my-3'>
                          <h4 className='text-sm text-neutral-500 capitalize font-normal'>{val.duration} - <span className='capitalize'>{val.batch_type}</span></h4>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </>
            )}
          </div>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default ExploreDrawerChild