export const groupProgramsByCategory = (programs) => {
  return programs.reduce((acc, program) => {
    const { category } = program;
    if (!acc[category.toLowerCase()]) {
      acc[category.toLowerCase()] = [];
    }
    acc[category.toLowerCase()].push(program);
    return acc;
  }, {});
};

/**
 * Sort the programs in category by status.
 */
export const sortVerticalProgramsByStatus = (programsData) => {
  // Define the priority for each program status
  const statusPriority = {
    "New Launch": 1, // New programs are shown first
    "Best Selling": 2, // Best selling programs are shown next
    "admission closed": 4, // Closed programs are shown last
  };

  // Sort the programs in category
  Object.values(programsData).forEach(programs =>
    programs.sort((a, b) => (statusPriority[a.status] || 3) - (statusPriority[b.status] || 3))
  );

  // Return the sorted programs in category
  return programsData;
};

/**
 * Sort the programs by status.
 */
export const sortPrograms = (programs) => {
  return programs.sort((a, b) => {
    // Define the priority for each status
    const priority = {
      "New Launch": 1,
      "Best Selling": 2,
      "admission closed": 4,
    };

    // Return the difference between the priority of the two programs
    return (priority[a.status] || 3) - (priority[b.status] || 3);
  });
}
