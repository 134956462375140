import React, { useEffect, useState } from "react";
import { STREAMS } from "../../../../Assets/constants/constants";
import { getCardColorAndBgColor } from "../../../../helpers/colors.helpers";
import { groupProgramsByCategory, sortVerticalProgramsByStatus } from "../../../../helpers/common.helpers";
import programs from "../../../../Assets/constants/programs.json";

const ExpTail = ({ isDropdownOpen, setIsDropdownOpen }) => {
  const [categorizedPrograms, setCategorizedPrograms] = useState([]);
  const [sideTab, setSideTab] = useState("CFO");

  const sortedStreams = STREAMS.slice().sort((a, b) => a.order - b.order);


  useEffect(() => {
    const fetchPrograms = groupProgramsByCategory(programs);
    const sortedPrograms = sortVerticalProgramsByStatus(fetchPrograms)
    setCategorizedPrograms(sortedPrograms || []);
  }, []);

  return (
    <div
      onMouseEnter={(e) => {
        setIsDropdownOpen(false);
      }}
      className={`absolute top-[100%] left-0 right-0 w-full xl:pl-[15rem] h-screen flex xl:justify-normal justify-center items-start bg-black/60 ${isDropdownOpen ? "opacity-100" : "opacity-0"
        } transition-all duration-150`}
      style={{ visibility: isDropdownOpen ? "visible" : "hidden" }}
    >
      <div
        className="max-w-[63rem] border-t h-[70vh] max-h-[470px] px-2 rounded-b-md overflow-hidden bg-white w-full flex"
        onMouseEnter={(e) => {
          e.stopPropagation();
          setIsDropdownOpen(true);
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          setIsDropdownOpen(false);
        }}
      >
        <div className="w-fit h-full bg-white">
          <div className="w-full flex flex-col gap-2 max-h-[470px] h-[70vh] overflow-y-auto custom-scrollbar py-3 px-2">
            {sortedStreams.map((val, index) => (
              <div
                className={`max-w-[340px] w-full rounded-md drop-shadow-md flex items-center justify-between gap-3 px-4 py-2.5 ${sideTab.toLocaleLowerCase() ===
                  val.category.toLocaleLowerCase()
                  ? "bg-universal text-white"
                  : "bg-white text-black"
                  } cursor-pointer`}
                onClick={() => setSideTab(val.category)}
                key={index}
              >
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4">
                    <val.icon
                      className={`w-full h-full ${sideTab.toLocaleLowerCase() ===
                        val.category.toLocaleLowerCase()
                        ? "text-white"
                        : "text-slate-900"
                        }`}
                    ></val.icon>
                  </div>
                  <h1 className="font-medium text-nowrap text-sm capitalize">
                    {val.category}
                  </h1>
                </div>
                <div className="w-5 h-5">
                  <svg
                    className="w-full h-full"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 6L15 12L9 18"
                      stroke={sideTab === val.category ? "white" : "#222222"}
                      strokeWidth="1.3"
                    />
                  </svg>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex-1 py-[26px]">
          <h1 className="font-bold text-xl mx-2 capitalize">{sideTab}</h1>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 px-2 py-2 max-h-[380px] h-[55vh] overflow-y-auto custom-scrollbar">
            {categorizedPrograms &&
              categorizedPrograms[sideTab.toLowerCase()]?.map((val, index) => (
                <a
                  href={`/programs/${val.id}`}
                  className={`w-full max-h-[160px] ${categorizedPrograms[sideTab.toLowerCase()]?.length === 1 ? "h-fit" : "h-full"} bg-white drop-shadow-md rounded-lg`}
                  onClick={() => setIsDropdownOpen(false)}
                  key={index}
                  type="div"
                >
                  <div className="w-full min-h-32 h-full flex flex-col justify-between">
                    <div className="w-full">
                      {/* ribbon */}
                      <div className="w-full flex justify-end px-4">
                        <div
                          style={{
                            backgroundColor: `${getCardColorAndBgColor(val.status).bgColor
                              }`,
                          }}
                          className={`px-3 py-[2px] rounded-b-lg`}
                        >
                          <h1
                            style={{
                              color: `${getCardColorAndBgColor(val.status).color
                                }`,
                            }}
                            className={`font-bold text-xs capitalize`}
                          >
                            {val.status}
                          </h1>
                        </div>
                      </div>
                      {/* image and heading */}
                      <div className="w-full px-4 flex gap-2 items-start mt-2">
                        <div className="w-8 h-8 mt-1 flex-shrink-0 border rounded-md">
                          <img
                            src={val.university_image}
                            alt="logo"
                            className="w-full h-full"
                          />
                        </div>
                        <div className="flex flex-col">
                          <h1 className="text-xs text-neutral-600 capitalize">
                            {val.university_name}
                          </h1>
                          <h1 className="text-neutral-700 font-medium">
                            {val.program_name}
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="w-full px-4 my-3">
                      <h4 className="text-sm text-neutral-500 capitalize font-normal">
                        {val.duration} -{" "}
                        <span className="capitalize">{val.batch_type}</span>
                      </h4>
                    </div>
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpTail;
